import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import classNames from 'classnames';

import Form from '@geneui/components/Form';
import Button from '@geneui/components/Button';
import Toaster from '@geneui/components/Toaster';
import Checkbox from '@geneui/components/Checkbox';
import Icon from '@geneui/components/Icon';

import FormField from 'components/FormField';
import PhoneNumberFields from 'components/PhoneNumberFields';
import ReCaptchaComponent from 'components/ReCaptchaComponent';

import { useStore } from 'store';
import useMount from 'utils/hooks/useMount';
import { getUserMinMaxAgeRange, groupBySteps } from 'utils/helpers';
import { useTranslator } from 'utils';
import useRegisterActions from 'utils/hooks/useRegisterAction';
import { useGeneric } from 'utils/hooks/useGeneric';
import { useConfig } from 'utils/hooks/useConfig';
import mask from 'utils/mask';

import { LOGIN } from 'configs/urls';
import {
  DROPDOWN_LABEL_KEYS,
  DROPDOWN_VALUE_KEYS,
  ENTER_KEY_CODE,
  IS_REGISTER,
  GENDER_LIST,
  CELL_PHONE,
  VERIFICATION_IDS,
  REGISTRATION_CONFIRM_TYPE_EMAIL,
  REGISTRATION_CONFIRM_TYPE_DEFAULT,
} from 'constants/defines';

import SMSVerify from '../SMSVerify';
import registrationMask from './registrationMask';

const GOOGLE_RE_CAPTCHA_ACTION = 'register';

const RegistrationForm = ({
  isVisible = true,
  isLoading,
  createUser,
  getSkinCurrencies,
  getRegistrationFields,
  setRegistrationSuccess,
  setTermsAndCondition,
}) => {
  const { t } = useTranslator();
  const history = useHistory();
  const { useDefaultActions } = useRegisterActions(IS_REGISTER.registration);
  const { maxAge, minAge } = getUserMinMaxAgeRange();

  const {
    state: {
      geolocation: { geocode },
      verifications: {
        smsVerificationCode: { isVisibleSMSVerify },
      },
    },
    actions: { getGeolocation, sendSmsVerificationCode, setIsVisibleSMSVerify },
  } = useStore();

  const { countries, brands, verificationConfigs } = useGeneric();
  const {
    config: { partnerName, reCaptcha, reCaptchaKey },
    partnerSkinCustomization: {
      css: { loginHighlightColor, loginSignInButtonColors },
    },
    mainCurrency,
  } = useConfig();

  const {
    data,
    setData,
    isValid,
    setIsValid,
    registerState,
    setRegisterState,
    changeRegisterState,
    registrationStepFields,
    setRegistrationStepFields,
  } = useDefaultActions();

  const [step, setStep] = useState(0);
  const [stepList, setStepList] = useState([]);
  const [stepValidList, setStepValidList] = useState({});

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const isVerificationTypeEnabled = useCallback(
    type =>
      Boolean(Number(verificationConfigs?.find(({ verificationType }) => verificationType === type)?.isEnabled ?? 0)),
    [verificationConfigs]
  );

  const smsVerify = isVerificationTypeEnabled(VERIFICATION_IDS.SMS);
  const emailVerify = isVerificationTypeEnabled(VERIFICATION_IDS.EMAIL);

  const lastStep = useMemo(() => stepList[stepList.length - 1], [stepList]);
  const country = useMemo(
    () => (geocode ? countries.find(country => country.code === geocode.countryCode) : undefined),
    [countries, geocode]
  );

  const smsCodeLengthValid = registerState.smsVerificationCode && registerState.smsVerificationCode.length >= 6;
  const termsAndCondAccepted = registerState.termsAndCond;

  const register = async token => {
    const credentials = {
      affiliate: registerState,
      isAgent: false,
      ...(token
        ? {
            reCaptcha: {
              token,
              action: GOOGLE_RE_CAPTCHA_ACTION,
            },
          }
        : {}),
    };

    const { data } = await createUser(credentials);

    if (data?.result) {
      Toaster.success({
        title: t('success'),
        message: t('registration'),
        iconProps: {
          isFilled: true,
        },
      });
      setRegistrationSuccess(emailVerify ? REGISTRATION_CONFIRM_TYPE_EMAIL : REGISTRATION_CONFIRM_TYPE_DEFAULT);
    }
  };

  const sendSmsVerification = () => {
    sendSmsVerificationCode({
      data: {
        affiliate: registerState,
        isAgent: false,
      },
      t,
    });
  };

  const handleKeyboard = async (event, handleReCaptchaVerify) => {
    if (event.keyCode === ENTER_KEY_CODE && isValid) {
      if (step === lastStep) {
        smsVerify && !smsCodeLengthValid
          ? sendSmsVerification()
          : handleReCaptchaVerify
          ? handleReCaptchaVerify()
          : await register();
      } else {
        const nextStep = stepList[step + 1];
        setStep(nextStep);
      }
    }
  };

  const changePopups = useCallback(() => {
    history.push(LOGIN);
  }, [history]);

  const getData = async () => {
    try {
      const [
        {
          data: { result: currencies = {} },
        },
        {
          data: { result: registrationFields = [] },
        },
      ] = await Promise.all([getSkinCurrencies(), getRegistrationFields()]);

      setData({
        countryCode: countries,
        gender: GENDER_LIST(t),
        mainCurrency: Object.values(currencies),
        preferredBrands: brands,
      });

      const fieldsData = Object.values(groupBySteps(registrationFields));
      setStepList(
        Array(fieldsData.length)
          .fill(0)
          .map((_, key) => key)
      );
      setRegistrationStepFields(fieldsData);
    } catch (e) {
      /*NOPE*/
    }
  };

  useMount(() => {
    getData();
    getGeolocation();
  });

  const { countryCode = [], mainCurrency: currenciesData = [] } = data;

  const geocodeCountryCode = geocode?.countryCode;

  useEffect(() => {
    const defaultCountryCode = countryCode.length === 1 ? countryCode[0].code : country?.code;
    const defaultCurrency =
      (country?.currencyCode
        ? currenciesData.find(currencyData => currencyData.iso === country.currencyCode)
        : undefined) ?? mainCurrency;

    (defaultCountryCode || currenciesData.length === 1) &&
      setRegisterState(prev => ({
        ...prev,
        countryCode: defaultCountryCode ?? prev.code,
        cellPhoneCountryCode: defaultCountryCode ?? prev.cellPhoneCountryCode,
        mainCurrency: currenciesData.length === 1 ? mainCurrency.iso : defaultCurrency?.iso,
      }));
  }, [
    registerState.code,
    countryCode,
    countryCode.length,
    currenciesData.length,
    data,
    mainCurrency,
    geocodeCountryCode,
    setRegisterState,
  ]);

  useEffect(() => {
    setStepValidList(prevState => ({ ...prevState, [step]: isValid }));
  }, [isValid, step]);

  const sharedProps = {
    inputSize: 'big',
    flexibility: 'full-width',
    cornerRadius: 'smooth-radius',
  };

  const clickHandler = token => {
    if (step === lastStep) {
      smsVerify && !smsCodeLengthValid ? sendSmsVerification() : register(token);
    } else {
      const nextStep = stepList[step + 1];
      setStep(nextStep);
    }
  };

  const handleChangeSteps = nextStep => {
    if (nextStep > step && stepValidList[step] && registerState.termsAndCond) {
      for (let i = 0; i < nextStep; i++) {
        if (!stepValidList[i]) {
          return;
        }
      }
      setStep(nextStep);
    } else if (nextStep < step) {
      setStep(nextStep);
    }
  };

  const handleChangeTermsAndConditions = useCallback(
    e => {
      setRegisterState(prevState => ({
        ...prevState,
        termsAndCond: e.target.checked,
      }));
    },
    [setRegisterState]
  );

  const changePhoneState = useCallback(
    e => {
      setRegisterState(prev => ({ ...prev, ...e }));
    },
    [setRegisterState]
  );

  const isDisabled = isLoading || !isValid || (isVisibleSMSVerify && !smsCodeLengthValid) || !termsAndCondAccepted;

  const isConfirm = smsVerify && isVisibleSMSVerify;
  const isSignUp = !smsVerify && step === lastStep;
  const action = isConfirm ? 'confirm' : isSignUp ? 'sign-up' : 'next';

  const onBackSMSVerify = () => {
    setIsVisibleSMSVerify(false);
    setRegisterState(prev => ({ ...prev, smsVerificationCode: undefined }));
  };

  const onChange = e => {
    const key = Object.keys(e)[0];
    const maskValue = registrationMask[key];
    if (maskValue) {
      changeRegisterState({ [key]: mask(e[key], maskValue) });
    } else {
      changeRegisterState(e);
    }
  };

  const registerForm = handleReCaptchaVerify => (
    <>
      <Form
        key={step}
        disableFooter
        onValidationChange={setIsValid}
        onKeyUp={event => handleKeyboard(event, handleReCaptchaVerify)}
      >
        {(registrationStepFields[step] || []).map(({ name, label, type, validationKey, isRequired }) => (
          <div className="authorisationFormField-bc" key={name}>
            {name !== CELL_PHONE ? (
              <FormField
                id={name}
                autoComplete="new-password"
                type={type === 'password' ? (!showPassword[name] ? 'password' : undefined) : type}
                labelKey={DROPDOWN_LABEL_KEYS[name]}
                valueKey={DROPDOWN_VALUE_KEYS[name]}
                data={data[name] || []}
                required={parseInt(isRequired)}
                placeholder={t(label)}
                value={registerState[name]}
                dataKey={name}
                validationKey={validationKey}
                label={t(label)}
                isMultiSelect={name === 'preferredBrands'}
                disabled={data[name] === 1 || (Array.isArray(data[name]) && data[name].length) === 1}
                onChange={onChange}
                isValid={
                  name === 'confirmPassword' ? registerState.password === registerState.confirmPassword : undefined
                }
                {...sharedProps}
                endAdornment={
                  name === 'password' || name === 'confirmPassword' ? (
                    <Icon
                      className="showPassword"
                      type={showPassword[name] ? 'bc-icon-activate-48' : 'bc-icon-inactivate-48'}
                      onClick={() =>
                        setShowPassword(prev => ({
                          ...prev,
                          [name]: !prev[name],
                        }))
                      }
                    />
                  ) : undefined
                }
                max={maxAge}
                min={minAge}
                pickerProps={{
                  defaultPreview: maxAge,
                }}
              />
            ) : (
              <PhoneNumberFields
                required={parseInt(isRequired)}
                phoneState={registerState}
                changePhoneState={changePhoneState}
              />
            )}
          </div>
        ))}
      </Form>
      <div id="termsAndConditionsContainer" className="tetmsNCon-bc authorisationFormForgotPass-bc">
        <Checkbox
          onChange={handleChangeTermsAndConditions}
          checked={registerState.termsAndCond}
          type="checkbox"
          appearance="minimal"
        />
        <span
          style={{ color: loginHighlightColor }}
          className="iAcceptTermsN-bc"
          onClick={() => setTermsAndCondition(true)}
        >
          {t('i-accept-the-terms-and-condition')}
        </span>
      </div>
    </>
  );

  const formContainer = handleReCaptchaVerify => (
    <>
      {isVisibleSMSVerify ? (
        <SMSVerify
          onBack={onBackSMSVerify}
          registerState={registerState}
          onChange={res => setRegisterState(prev => ({ ...prev, smsVerificationCode: res }))}
        />
      ) : (
        registerForm(handleReCaptchaVerify)
      )}

      <Button
        style={{ ...loginSignInButtonColors }}
        className="authorisationFormSubmit-bc"
        size="big"
        disabled={isDisabled}
        onClick={() => (handleReCaptchaVerify ? handleReCaptchaVerify() : clickHandler())}
      >
        {t(action)}
      </Button>
    </>
  );

  return isVisible ? (
    <div className="authorisationFormColInner-bc">
      <h2 id="title" className="authorisationFormTitle-bc" style={{ color: loginHighlightColor }}>
        {partnerName}
      </h2>
      <h4 id="subTitle" className="authorisationFormSubtitle-bc">
        {t('sign-up')}
      </h4>
      <div className="authorisationFormCallToRegistration-bc">
        <p>
          {t('already-have-an-account')}?
          <Link style={{ color: loginHighlightColor }} to="#" onClick={changePopups}>
            {t('sign-in')}
          </Link>
        </p>
        {!isVisibleSMSVerify && stepList.length > 1 && (
          <div className="dotList">
            {stepList.map(item => (
              <button
                key={item}
                className={classNames('dot', { activeDot: step === item })}
                onClick={() => handleChangeSteps(item)}
              />
            ))}
          </div>
        )}
      </div>
      <div className="login-holder-item-wrap-form">
        {reCaptcha && reCaptchaKey ? (
          <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
            <ReCaptchaComponent onSuccess={clickHandler} action={GOOGLE_RE_CAPTCHA_ACTION}>
              {formContainer}
            </ReCaptchaComponent>
          </GoogleReCaptchaProvider>
        ) : (
          formContainer()
        )}
      </div>
    </div>
  ) : null;
};

export default RegistrationForm;
