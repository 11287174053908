import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Home from 'components/Login/HomeSection';
import Commissions from 'components/Login/CommissionsSection';
import EarnMoney from 'components/Login/EarnMoneySection';
import Faq from 'components/Login/FaqSection';
import LatestNews from 'components/Login/LatestNewsSection';
import AboutUs from 'components/Login/AboutUsSection';
import PaymentMethod from 'components/Login/PaymentMethodSection';
import Testimonials from 'components/Login/TestimonialsSection';
import ContactUs from 'components/Login/ContactUsSection';
import Header from 'components/Login/HeaderSection';
import MarketingTools from 'components/Login/MarketingToolsSection';
import Social from 'components/Login/Social';

import { useStore } from 'store';
import Connection from 'utils/connection';
import useMount from 'utils/hooks/useMount';
import useScrollTo from 'utils/hooks/useScrollTo';
import init from 'utils/reducers/LandingPage/init';
import reducer from 'utils/reducers/LandingPage/reducer';
import { isMobile } from 'utils';

import {
  SET_COMMISSION_CPA,
  SET_COMMISSION_REVENUE,
  SET_COMMISSION_SUB_AFFILIATES,
  SET_COMMISSION_HYBRID,
  SET_TERMS_AND_CONDITIONS,
  SET_FAQ,
  SET_FAQ_SPLICE,
  SET_ABOUT_US,
  SET_SLOGAN,
} from 'utils/reducers/LandingPage/actions';
import { COMMISSION_PLAN_TYPES, LOG_OUT_PAGE_CALLS, SKIN_TYPES } from 'constants/defines';

import { GET_CURRENT_LANG_DICTIONARY_ITEM } from 'constants/api';
import '../../assets/icon-font/style.css';

import { useGeneric } from 'utils/hooks/useGeneric';
import { useConfig } from 'utils/hooks/useConfig';
import { useLandingPageLanguageSelector } from 'reduxStore/slices/settingsSlice/selectors';
import { useGetLatestNewsQuery } from 'reduxStore/services/socialShare';

const refs = [];

const Login = ({ images, colors: propColors, readOnly }) => {
  const { brands } = useGeneric();
  const {
    config: { partnerName, skinType },
    partnerSkinCustomization: {
      logoPaths: { logo },
      css,
      logoPaths: { backgroundImage, backgroundMobileImage },
    },
    socialLinks,
  } = useConfig();

  const { data, isFetching: isLoadingLatestNews } = useGetLatestNewsQuery();
  const socialShareList = data?.result ?? {};

  const landingPageLanguage = useLandingPageLanguageSelector();

  const {
    state: {
      layout: { elements },
    },
    actions: { getLayoutElements },
  } = useStore();

  const { CPA, FAQ, HYBRID, SLOGAN, REVENUE, ABOUT_US, SUB_AFFILIATES, TERMS_AND_CONDITIONS } = LOG_OUT_PAGE_CALLS;

  const [isScrolled, setIsScrolled] = useState(false);
  const [state, dispatch] = useReducer(reducer, null, init);
  const [isBackgroundImageLoaded, setIsBackgroundImageLoaded] = useState(false);

  const layoutElementsCommissions = useMemo(
    () =>
      elements.filter(
        element => Object.values(COMMISSION_PLAN_TYPES).indexOf(element.layoutElementId) !== -1 && element.isEnabled
      ),
    [elements]
  );

  const isLayoutElement = useCallback(
    elementName => {
      const element = elements.find(item => item.label === elementName);
      return element?.isEnabled;
    },
    [elements]
  );

  const scroll = useScrollTo(refs);

  const handleChange = useCallback((type, payload) => {
    dispatch({ type: type, payload: payload });
  }, []);

  const getProductStatistics = useCallback(async () => {
    const data = {
      keyName: [CPA, FAQ, SLOGAN, HYBRID, REVENUE, ABOUT_US, SUB_AFFILIATES, TERMS_AND_CONDITIONS],
    };

    const { result } = await Connection.post(GET_CURRENT_LANG_DICTIONARY_ITEM, data);

    if (result) {
      const jsonFaq = JSON.parse(result[FAQ]);
      const faqSplice = jsonFaq.slice(jsonFaq.length / 2);

      handleChange(SET_SLOGAN, result[SLOGAN]);
      handleChange(SET_COMMISSION_CPA, result[CPA]);
      handleChange(SET_COMMISSION_REVENUE, result[REVENUE]);
      handleChange(SET_COMMISSION_SUB_AFFILIATES, result[SUB_AFFILIATES]);
      handleChange(SET_COMMISSION_HYBRID, result[HYBRID]);
      handleChange(SET_TERMS_AND_CONDITIONS, result[TERMS_AND_CONDITIONS]);
      handleChange(SET_ABOUT_US, result[ABOUT_US]);
      handleChange(SET_FAQ, jsonFaq.slice(0, jsonFaq.length / 2));
      handleChange(SET_FAQ_SPLICE, faqSplice);
    }
  }, [ABOUT_US, CPA, FAQ, HYBRID, REVENUE, SLOGAN, SUB_AFFILIATES, TERMS_AND_CONDITIONS, handleChange]);

  useMount(() => {
    getLayoutElements();
  });

  useEffect(() => {
    getProductStatistics();
  }, [getProductStatistics, landingPageLanguage]);

  const handleScroll = e => {
    const { scrollTop } = e.currentTarget;
    if (isScrolled && scrollTop === 0) {
      setIsScrolled(false);
    }

    if (!isScrolled && scrollTop > 0) {
      setIsScrolled(true);
    }
  };

  const setRefs = (ref, key) => (refs[key] = ref);

  const isPageLoaded =
    state.pageText.aboutUs !== undefined &&
    state.pageText.slogan !== undefined &&
    state.pageText.termsAndConditions !== undefined &&
    isBackgroundImageLoaded;

  const colors = useMemo(() => propColors ?? css, [propColors, css]);

  const sharedProps = {
    state,
    dispatch,
    colors,
    isLayoutElement,
  };

  return (
    <div style={{ visibility: isPageLoaded ? 'unset' : 'hidden', height: '100%' }}>
      <div className={classNames('landWrapper-bc', { statusScrolled: isScrolled })} onScroll={handleScroll}>
        <Header
          readOnly={readOnly}
          logo={images && images.logo ? images.logo.result : '/'.concat(logo)}
          isScrolled={isScrolled}
          scroll={key => scroll(key)}
          isLayoutElement={isLayoutElement}
          layoutCommissionPlanList={layoutElementsCommissions}
          partnerSkinCustomization={colors}
          isLoading={isLoadingLatestNews}
        />
        <Home
          {...sharedProps}
          brands={brands}
          partnerName={partnerName}
          setRefs={setRefs}
          backgroundImage={
            images && images.backgroundImage
              ? images.backgroundImage?.result
              : !isMobile()
              ? backgroundImage
              : backgroundMobileImage
          }
          onImageLoad={() => setIsBackgroundImageLoaded(true)}
        />
        {layoutElementsCommissions.length && skinType !== SKIN_TYPES.FEED ? (
          <Commissions {...sharedProps} setRefs={setRefs} layoutCommissionPlanList={layoutElementsCommissions} />
        ) : null}
        {isLayoutElement('earn-money') ? <EarnMoney scroll={scroll} pageColor={colors.mainColor} /> : null}
        {isLayoutElement('marketing-tools') ? (
          <MarketingTools setRefs={setRefs} readOnly={readOnly} pageColor={colors.mainColor} />
        ) : null}
        {isLayoutElement('faq') ? <Faq {...sharedProps} partnerName={partnerName} setRefs={setRefs} /> : null}
        <LatestNews pageColor={colors.mainColor} socialShareList={socialShareList} />
        {isLayoutElement('social') ? <Social socialLinks={socialLinks} /> : null}
        {isLayoutElement('about-us') ? <AboutUs {...sharedProps} readOnly={readOnly} setRefs={setRefs} /> : null}
        {isLayoutElement('payment') ? <PaymentMethod pageColor={colors.mainColor} /> : null}
        <div className="footerShapeWrapper-bc">
          {isLayoutElement('testimonials') ? <Testimonials setRefs={setRefs} pageColor={colors.mainColor} /> : null}
          {isLayoutElement('contact-us') ? (
            <ContactUs setRefs={setRefs} readOnly={readOnly} pageColor={colors.mainColor} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  images: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  colors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  readOnly: PropTypes.bool,
};

Login.defaultProps = {
  images: false,
  readOnly: false,
};

export default Login;
