import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Loader from 'components/Loader';
import navBarRoute from 'sections/Header/Navbar';

import { useGeneric } from 'utils/hooks/useGeneric';
import { useTranslator, Connection, isMobile } from 'utils';
import { documentDirectionChange } from 'utils/helpers';
import { CHANGE_LANGUAGE } from 'constants/api';

import { useLandingPageLanguageSelector } from 'reduxStore/slices/settingsSlice/selectors';
import { setLandingPageLanguage } from 'reduxStore/slices/settingsSlice';
import { INVALIDATION_TAGS, api } from 'reduxStore/api';

import HeaderSectionDesktop from './HeaderSectionDesktop';
import HeaderSectionMobile from './HeaderSectionMobile';

const Header = ({
  readOnly,
  scroll,
  logo,
  isScrolled,
  isLayoutElement,
  partnerSkinCustomization,
  layoutCommissionPlanList,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const landingPageLanguage = useLandingPageLanguageSelector();

  const { allAvailableLanguages = [], languages } = useGeneric();

  const { setLanguage } = useTranslator();
  const [isBusy, setIsBusy] = useState(false);
  const [langValue, setLangValue] = useState(false);

  const header = navBarRoute(isLayoutElement, layoutCommissionPlanList.length);
  const selectedLanguageLabel = useMemo(
    () => languages?.find(({ id }) => id === landingPageLanguage)?.alpha3,
    [landingPageLanguage, languages]
  );

  const openLanguageList = useCallback(() => {
    setLangValue(!langValue);
  }, [langValue, setLangValue]);

  const changeLanguage = useCallback(
    async key => {
      setIsBusy(true);
      await Connection.post(CHANGE_LANGUAGE, { key });
      dispatch(api.util.invalidateTags([INVALIDATION_TAGS.GET_LATEST_NEWS]));
      dispatch(setLandingPageLanguage(key));
      setLanguage(key.replace('_', '-'));
      setLangValue(false);
      setIsBusy(false);
    },
    [setLanguage]
  );

  useEffect(() => {
    if (landingPageLanguage) {
      documentDirectionChange(allAvailableLanguages, landingPageLanguage);
    }
  }, [allAvailableLanguages, landingPageLanguage]);

  const sharedProps = {
    logo,
    scroll,
    header,
    langValue,
    languages,
    isScrolled,
    changeLanguage,
    openLanguageList,
    selectedLanguageLabel,
    ...partnerSkinCustomization,
  };

  return (
    <>
      {isMobile() ? (
        <HeaderSectionMobile {...sharedProps} />
      ) : (
        <HeaderSectionDesktop {...sharedProps} readOnly={readOnly} landingPageLanguage={landingPageLanguage} />
      )}
      <Loader isBusy={isBusy || isLoading} />
    </>
  );
};

Header.propTypes = {
  logo: PropTypes.string.isRequired,
  scroll: PropTypes.func.isRequired,
  isLayoutElement: PropTypes.func.isRequired,
  layoutCommissionPlanList: PropTypes.array.isRequired,
  isScrolled: PropTypes.bool.isRequired,
  partnerSkinCustomization: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default Header;
